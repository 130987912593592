const TESTCOMP = '/testComp';
const LOGIN = '/login';
const MAIN = '/main';
const SIGNUP = '/signup';
const ADMISSION = '/admission';



export {
    TESTCOMP,
    LOGIN,
    MAIN,
    SIGNUP,
    ADMISSION,
}