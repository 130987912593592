export function getSrmColor(color) {
    return color;
}

// 경로확인
// export function urlSelector(path) {
//     let retVal = '';
//     if (path.indexOf('today') > -1) {
//         retVal = 'today';
//     }
//     if (path.indexOf('careplan') > -1) {
//         retVal = 'careplan';
//     }
//     if (path.indexOf('guide') > -1) {
//         retVal = 'guide';
//     }
//     if (path.indexOf('manage') > -1) {
//         retVal = 'manage';
//     }
//     if (path.indexOf('careNote') > -1) {
//         retVal = 'carenote';
//     }
//     return retVal;
// }

/** gruop array : [{name:'사과',...},{name:'사과',...}]
   * 같은 value값으로 배열을 묶어준다.
   */
export const groupBy = (array, f) => {
    const groups = {};
    array.forEach(function (o) {
        const group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
        return groups[group];
    });
}

/** 00:00표기 */
export const format_dp_time = (gapT) => {
    let temp = String(Math.floor(gapT / 60)); // 부분 두자리 계산 mm
    if (Math.floor(gapT / 60) < 10) {
        temp = "0" + temp;
    }

    temp = temp + ":"; // mm:ss의 : 이 부분추가 //초부분 두자리 계산 ss
    if (gapT % 60 < 10) {
        temp = temp + "0";
    }

    temp = temp + (gapT % 60);
    return temp;
}

export const validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const getUserAgent = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    let userType = '';
    if (userAgent.indexOf('android') !== -1) {
        userType = 'android';
    } else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
        userType = 'iphone';
    } else {
        // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
        userType = 'etc';
    }
    return userType;
};

export const getBrowserType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    let browserName;
    switch (true) {
        case userAgent.indexOf('edge') > -1:
            browserName = 'MS Edge'; //  Ms 엣지
            break;
        case userAgent.indexOf('edg/') > -1:
            browserName = 'Edge (chromium based)'; //  크롬 기반 엣지
            break;
        case userAgent.indexOf('opr') > -1:
            browserName = 'Opera'; //  오페라
            break;
        case userAgent.indexOf('chrome') > -1:
            browserName = 'Chrome'; //  크롬
            break;
        case userAgent.indexOf('trident') > -1:
            browserName = 'MS IE'; //  익스플로러
            break;
        case userAgent.indexOf('firefox') > -1:
            browserName = 'Mozilla Firefox'; //  파이어폭스
            break;
        case userAgent.indexOf('safari') > -1:
            browserName = 'Safari'; //  사파리
            break;
        default:
            browserName = 'other'; //  기타
    }
    return browserName;
};

export const checkDeviceType = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return 'mo';
    } else {
        return 'pc';
    }
    var os, ua = navigator.userAgent;
    if (ua.match(/Win(dows )?NT 6\.0/)) {
        os = "Windows Vista";
    } else if (ua.match(/Win(dows )?(NT 5\.1|XP)/)) {
        os = "Windows XP";
    } else {
        if ((ua.indexOf("Windows NT 5.1") != -1) || (ua.indexOf("Windows XP") != -1)) {
            os = "Windows XP";
        } else if ((ua.indexOf("Windows NT 7.0") != -1) || (ua.indexOf("Windows NT 6.1") != -1)) {
            os = "Windows 7";
        } else if ((ua.indexOf("Windows NT 8.0") != -1) || (ua.indexOf("Windows NT 6.2") != -1)) {
            os = "Windows 8";
        } else if ((ua.indexOf("Windows NT 8.1") != -1) || (ua.indexOf("Windows NT 6.3") != -1)) {
            os = "Windows 8.1";
        } else if ((ua.indexOf("Windows NT 10.0") != -1) || (ua.indexOf("Windows NT 6.4") != -1)) {
            os = "Windows 10";
        } else if ((ua.indexOf("iPad") != -1) || (ua.indexOf("iPhone") != -1) || (ua.indexOf("iPod") != -1)) {
            os = "Apple iOS";
        } else if (ua.indexOf("Android") != -1) {
            os = "Android OS";
        } else if (ua.match(/Win(dows )?NT( 4\.0)?/)) {
            os = "Windows NT";
        } else if (ua.match(/Mac|PPC/)) {
            os = "Mac OS";
        } else if (ua.match(/Linux/)) {
            os = "Linux";
        } else if (ua.match(/(Free|Net|Open)BSD/)) {
            os = RegExp.$1 + "BSD";
        } else if (ua.match(/SunOS/)) {
            os = "Solaris";
        }
    }
    if (os.indexOf("Windows") != -1) {
        if (navigator.userAgent.indexOf('WOW64') > -1 || navigator.userAgent.indexOf('Win64') > -1) {
            os += ' 64bit';
        } else {
            os += ' 32bit';
        }
    }
    return os
};