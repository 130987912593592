import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import * as URL_LISTS from '@/libs/url_lists';
import authenticate from '@/routes/authenticate';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  // 레이아웃에 따른 로드 페이지 처리
  routes: [
    {
      path: '/',
      // 사이드, 탑바 없이 풀 페이지
      component: () => import('@/components/layouts/full/LayoutFull.vue'),
      children: [
        {
          path: '/',
          redirect: '/admission'
        },
        {
          path: '/admission',
          name: 'admission',
          component: () => import('@/views/admission/AdmissionPage.vue')
        },
        {
          path: '/main',
          name: 'main',
          component: () => import('@/views/MainPage.vue'),
          beforeEnter: authenticate
          // meta: { auth: true },
        },
        {
          path: '*',
          component: () => import('@/views/NotFoundPage.vue')
        }
      ]
    }
    // {
    //   path: '/',
    //   // 사이드, 텝바가 있는 페이지
    //   component: () => import('@/components/layouts/vertical/LayoutVertical'),
    //   children: [

    //     // {
    //     //   path: URL_LISTS.MAIN,
    //     //   component: () => import('@/views/today/TodayPage'),
    //     //   meta: { auth: true } // 인증이 필요한 경우
    //     // },
    //   ]
    // }
  ]
});

router.beforeEach((to, from, next) => {
  // console.log(to.meta.auth);
  // if (to.meta.auth) {
  //   console.log('로그인 페이지 이동');
  //   next('/');
  //   return;
  // }
  next();
});

export default router;
