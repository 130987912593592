<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import { deleteCookie } from '@/utils/cookies';
import { visitStopLogSave } from '@/api/auth';
import { getItem, setItem, removeItem } from '@/storage/storage';
export default {
  name: 'Philips-Metaverse-Booth',
  metaInfo() {
    return {
      title: '필립스 인공호흡기 Trilogy EV300 &amp; Trilogy Evo 메타버스',
    };
  },
  computed: {
    isUserLogin() {
      return this.$store.getters.isLogin;
    },
  },
  created() {
    this.initLocale();
  },
  mounted() {
    // window.addEventListener('beforeunload', function () {
    //   const visitInfo = getItem('visiterInfo');
    //   console.log(visitInfo);
    //   if (visitInfo) {
    //     console.log('제거');
    //     visitStopLogSave(visitInfo);
    //     removeItem('visiterInfo');
    //   }
    // });
    /* socket io 이벤트 리스너 등록 */
    // this.$socket.on('connect', () => {
    //   console.log('connect');
    // });
    // this.$socket.on('disconnect', () => {
    //   console.log('disconnect');
    // });
    // console.log(window.location.hostname); // 3.38.168.159
    // 리다이렉션
    if (window.location.hostname === '3.38.168.159') {
      window.location.href = 'https://www.philipsmetaverse.co.kr';
    }
  },
  methods: {
    initLocale() {
      const cookieLocale = this.$cookies.get('gch_user_locale');
      console.log(cookieLocale);
      if (cookieLocale === null || cookieLocale === undefined || cookieLocale === '') {
        let locale = navigator.language || navigator.userLanguage;
        locale = locale.substring(0, 2);
        if (locale !== 'en') locale = 'ko';
        this.$i18n.locale = locale;
      } else {
        this.$i18n.locale = cookieLocale;
      }
      this.$moment().locale(this.$i18n.locale);
    },
    getRouterParams() {
      console.log(this.$router.currentRoute.query);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/layout';
@import 'assets/scss/common';
.app {
  height: 100%;
  // background-color: $page-background-color;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
