// 로그인, 회원 가입, (ex) 회원 탈퇴
import { instance, logout, fileupload, login, signup, log, activity, product } from './index';

const reqData = {
  data: {}
};

// 입장 API
function visitStartLogSave(visitData) {
  return log
    .post('/logS', { data: visitData })
    .then(res => {
      return res.data[0];
    })
    .catch(err => {
      return err;
    });
}
// 퇴장 API
function visitStopLogSave(visitData) {
  return log
    .post('/logStopS', { data: visitData })
    .then(res => {
      return res.data[0];
    })
    .catch(err => {
      return err;
    });
}
// activity 저장 API
function activityLogSave(activityData) {
  return activity
    .post('/logS', { data: activityData })
    .then(res => {
      return res.data[0];
    })
    .catch(err => {
      return err;
    });
}
// booth 저장 API
function boothLogSave(boothData) {
  return product
    .post('/logS', { data: boothData })
    .then(res => {
      return res.data[0];
    })
    .catch(err => {
      return err;
    });
}
// 회원가입 API
function signUp(userData) {
  reqData.data = userData;
  return signup
    .post('', reqData)
    .then(res => {
      return res.data[0][0];
    })
    .catch(err => {
      return err;
    });
}

// 로그인 API
function loginUser(userData) {
  reqData.data = userData;
  return login
    .post('', reqData)
    .then(res => {
      return res.data[0][0];
    })
    .catch(err => {
      return err;
    });
}

// 로그아웃 API
function logOutUser(userData) {
  reqData.data = userData;
  return logout.post('', reqData);
}

// 파일업로드 API
function queryfileUpload(formData) {
  const config = {
    header: { 'content-type': 'multipart/form-data' }
  };
  // reqData.data = formData;
  return fileupload.post('image', formData, config);
}

export {
  visitStartLogSave,
  visitStopLogSave,
  signUp,
  loginUser,
  logOutUser,
  queryfileUpload,
  activityLogSave,
  boothLogSave
};
