export const mainContentData = (id) => {
    let data = {
        id: '',
        type: '',
        fileName: '',
        delayTime: 0,
        contentText: {
            title: '',
            subTitle: '',
        },
    };
    switch (id) {

        case 'ER':
            data.id = id;
            data.type = 'vod';
            data.fileName = 'ER_230516';
            data.delayTime = 2700,
                data.contentText = {
                    title: '<b>간편하고 신속한 셋업</b> <br/> Quick and easy setup',
                    subTitle:
                        '',
                };
            break;
        case 'Corridor':
            data.id = id;
            data.type = 'vod';
            data.fileName = 'Hallway_230911';
            data.delayTime = 2700,
                data.contentText = {
                    title: '<b>배터리 용량 강화로 확장된 이동성</b><br/>Extended portability for intra-hospital transport',
                    subTitle:
                        '내장형 배터리와 탈착형 배터리 장착 시 최대 15시간까지 사용할 수 있습니다.<br/><span>15-hours of battery life with an internal and detachable battery.</span>',
                };
            break;
        case 'ICU':
            data.id = id;
            data.type = 'vod';
            data.fileName = 'ICU_230516';
            data.delayTime = 3000,
                data.contentText = {
                    title: '<b>효율적인 의사결정을 도와주는 다양한 모니터링 기능과 치료 모드</b><br/><span>Advanced monitoring and therapy modes for proactive decision making</span>',
                    subTitle:
                        '',
                };
            break;
        case 'WARD':
            data.id = id;
            data.type = 'vod';
            data.fileName = 'General_Ward_230516';
            data.delayTime = 6000,
                data.contentText = {
                    title: '<b>효율적인 원내 원격 모니터링</b><br/>Connectivity for central monitoring',
                    subTitle:
                        'Philips Intellivue monitor와 연결하여 인공호흡기 데이터를 <b>central monitoring station에서 원격으로 모니터링</b>할 수 있습니다 <br/><span>Ability to connect to Philips Intellivue monitors and <b>remotely view ventilator settings, performance, and alarms from central monitoring stations.</b></span>',
                };
            break;
        case 'Home':
            data.id = id;
            data.type = 'vod';
            data.fileName = 'HOME_230516';
            data.delayTime = 17500,
                data.contentText = {
                    title: '<b>지속적인 재택 모니터링</b><br/>Continuity of monitoring',
                    subTitle:
                        '집에서도 간편하게 <b>SpO2, EtCO2 를 인공호흡기에 연결하여 모니터링</b>할 수 있습니다.<br/><span><b>SpO2 and EtCO2 monitoring</b> at the comfort of patient’s home.</span>',
                };
            break;
        case 'Product_Info':
            data.id = id;
            data.type = 'booth';
            data.fileName = '';
            data.delayTime = 500,
                data.contentText = {
                    title: 'Product Info',
                    subTitle:
                        '부스 체험을 유도하는 텍스트가 들어갑니다.',
                };
            break;
    }
    return data;
}

export const piContentData = (id) => {
    let data = {
        id: '',
        title: '',
        type: '',
        fileName: '',
    };
    switch (id) {

        case 'MainVideo':
            data.id = id;
            data.title = 'Trilogy EV300 & Trilogy Evo introduction';
            data.type = 'vod';
            data.fileName = 'Main_Video_230509';
            break;

        case 'EV300PlusEvo360':
            data.id = id;
            data.title = 'Trilogy EV300 & Trilogy Evo features introduction';
            data.type = 'vod';
            data.fileName = 'EV300__Evo_230911';
            break;

        case 'ContourDeluxe':
            data.id = id;
            data.title = 'Contour Deluxe';
            data.type = 'vod';
            data.fileName = 'Contour_Deluxe_230911';
            break;

        case 'Af531':
            data.id = id;
            data.title = 'AF531';
            data.type = 'vod';
            data.fileName = 'AF531_230911';
            break;

        case 'PerforMax':
            data.id = id;
            data.title = 'PerforMax';
            data.type = 'vod';
            data.fileName = 'PerforMax_230911';
            break;

        case 'PlatformBrochure':
            data.id = id;
            data.title = 'Trilogy EV300 & Trilogy Evo brochure';
            data.type = 'brochure';
            data.fileName = 'Platform_Brochure_230509';
            break;

        case 'MaskBrochure':
            data.id = id;
            data.title = 'NIV mask brochure';
            data.type = 'brochure';
            data.fileName = 'Mask_brochure_230509';
            break;
    }
    return data;
}
