import axios from 'axios';
import { setInterceptors } from './common/interceptors';

// 액시오스 초기화 함수
function createInstance(url = '') {
  return axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}${url}`
  });
}

// 액시오스 인증정보화 함께가야할 endpoint 함수
function createInstanceWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}${url}`
  });
  return setInterceptors(instance);
}

export const instance = createInstance();
export const login = createInstanceWithAuth('login');
export const signup = createInstanceWithAuth('signup');
export const logout = createInstanceWithAuth('logout');
export const logsave = createInstanceWithAuth('logs');
export const survey = createInstanceWithAuth('survey');
export const inquiry = createInstanceWithAuth('inquiry');
export const fileupload = createInstanceWithAuth('fileupload');

// philips front
export const log = createInstance('log/visited');
export const activity = createInstance('log/activity');
export const product = createInstance('log/product');
